import useJwt from '@/auth/jwt/useJwt'

/**
 * Devolver si el usuario ha iniciado sesión
 * Esto depende completamente de usted y de cómo desea almacenar el token en su aplicación frontend
 * e.g. Si está utilizando cookies para almacenar la aplicación, actualice esta función.
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('accessToken')
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * Esta función se utiliza para la navegación de ruta con fines de demostración.
 * En la aplicación real, no necesitará esta función porque su aplicación navegará a la misma ruta para cada usuario independientemente de su capacidad
 * Tenga en cuenta que el campo de función es solo para mostrar el propósito de que no lo usa nada en la interfaz
 * Estamos revisando el rol solo por facilidad
 * NOTE: Si tiene diferentes páginas para navegar según la capacidad del usuario, esta función puede ser útil. Sin embargo, debe actualizarlo.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  console.log('Buscando pagina de incio para usuario ..',userRole);
  if (userRole === 'Administrador') return { name:'home'}
  if (userRole === 'Integrador') return { name: 'home' }
  if (userRole === 'Consultor') return { name: 'home' }
  return { name: 'login' }
}
