import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import horizontalMenu from './horizontal-menu'
Vue.use(Vuex)
//console.log('Iniciar tienda...')
export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    horizontalMenu
  },
  strict: process.env.DEV,
})
