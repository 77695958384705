import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'

import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/dashboard/Analytics.vue'),
      meta: {
        pageTitle: 'Dashboard',
        resource: 'Dashboard',
        action:'read'
      },
    },
      // *===============================================---*
      // *--------- USER ---- ---------------------------------------*
      // *===============================================---*
      {
        path: '/clientes/list',
        name: 'clients-list',
        component: () => import('@/views/clientes/clientes-list/ClientesList.vue'),
        meta:{
          resource:'clientes',
          action:'listado'
        }
      },
      {
        path: '/clientes/edit/:id',
        name: 'clients-edit',
        component: () => import('@/views/clientes/clientes-edit/ClienteEdit.vue'),
        meta:{
          resource:'clientes',
          action:'editar'
        }
      },
      
      {
        path: '/config/permisos',
        name: 'config-permission',
        component: () => import('@/views/configuracion/ConfigViewPermissions.vue'),
        meta:{
          resource:'config-permission',
          action:'editar'
        }
      },
  // *===============================================---*
  // *--------- PRODUCTOS ---- ---------------------------------------*
  // *===============================================---*
      {
        path: '/config/productos',
        name: 'config-productos',
        component: () => import('@/views/productos/ProductList.vue'),
        meta:{
          resource:'config-productos',
          action:'listado'
        }
      },
  // *===============================================---*
  // *--------- EMPRESA ---- ---------------------------------------*
  // *===============================================---*
      {
        path: '/empresa/listado',
        name: 'empresas-list',
        component: () => import('@/views/empresas/EmpresasList.vue'),
        meta:{
          resource:'empresa',
          action:'listado'
        }
      },
      // *===============================================---*
      // *--------- FACTURAS ---- ---------------------------------------*
      // *===============================================---*
      // Invoice
      {
        path: '/invoice/list',
        name: 'invoice-list',
        component: () => import('@/views/invoice/invoice-list/InvoiceList.vue'),
        meta:{
          resource:'invoce',
          action:'listado'
        }
      },
      {
        path: '/invoice/preview/:id',
        name: 'invoice-preview',
        component: () => import('@/views/invoice/invoice-preview/InvoicePreview.vue'),
        meta:{
          resource:'invoce',
          action:'invoice-preview'
        }
      },
      {
        path: '/invoice/add/',
        name: 'invoice-add',
        component: () => import('@/views/invoice/invoice-add/InvoiceAdd.vue'),
        meta:{
          resource:'invoce',
          action:'invoice-add'
        }
      },
      {
        path: '/invoice/edit/:id',
        name: 'invoice-edit',
        component: () => import('@/views/invoice/invoice-edit/InvoiceEdit.vue'),
        meta:{
          resource:'invoce',
          action:'invoice-edit'
        }
      },
      // *===============================================---*
      // *--------- USUARIO ---- ---------------------------------------*
      // *===============================================---*
      {
        path: '/users/list',
        name: 'users-list',
        component: () => import('@/views/user/users-list/UsersList.vue'),
        meta:{
          resource:'usuarios',
          action:'listado'
        }
      },
      {
        path: '/users/view/:id',
        name: 'users-view',
        component: () => import('@/views/user/users-view/UsersView.vue'),
      },
      {
        path: '/users/edit/:id',
        name: 'users-edit',
        component: () => import('@/views/user/users-edit/UsersEdit.vue'),
      },

      {
        path: '/empresa/edit/:id',
        name: 'empresas-edit',
        component: () => import('@/views/empresas/empresa-edit/EmpresaEdit.vue'),
        meta:{
          resource:'empresa',
          action:'editar'
        }
      },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'no-authorized',
      component: () => import('@/views/NotAutorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Noautorizado',
        action:'read'
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to,_,next)=>{
  const isLoggedIn = isUserLoggedIn();
     console.log('this is logedin=>',isLoggedIn);
     console.log(to)
       if (!canNavigate(to)) {
      console.log('Entraaaqui...____');
      // Redirect to login if not logged in
      if (!isLoggedIn) return next({ name: 'login' });
      // If logged in => not authorized
      return next({ name: 'no-authorized' })
    }

    // Redirigir si está conectado
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData()
      console.log('Redirigir si está conectado--',userData);
      next(getHomeRouteForLoggedInUser(userData ? userData.rol : null))
    }
    console.log('llega aquiii...');
  return next()
});

// ? For splash screen
// Quitar afterEach hook si no está usando la pantalla de bienvenida
router.afterEach(() => {
  //Eliminar carga inicial
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
