import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Maneja la recuperación automática de habilidades anteriores si ya ha iniciado sesión como usuario
// ? Puede actualizar esto si almacena las habilidades del usuario en un lugar más seguro
// ! Cualquiera puede actualizar localStorage, así que tenga cuidado y actualice esto
const userData = JSON.parse(localStorage.getItem('userData'))
//console.log('Entraa una vez -------');
const existingAbility = userData ? userData.permisos : null

export default new Ability(existingAbility || initialAbility)
