import Vue from 'vue'
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import { WS_URL } from '@/env/environment'
const socket = io(WS_URL,{
    'extraHeaders': {
        'Authorization': `${localStorage.getItem('accessToken')}`
    },
    path:'/socket/'
});
Vue.use(VueSocketIOExt, socket);
